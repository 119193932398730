import React from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import { FeaturedWrapper } from './index.style'
import { Heading1 } from '@components/TextStyles'
import Spacer from '@components/Spacer'
import Items from '../Items'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import HorizontalRule from '@components/HorizontalRule'

const Featured = ({ regions }) => {
  return (
    <FeaturedWrapper>
      <Container>
        <AnimateSplitText>
          <Heading1>Featured Deployment Resources</Heading1>
        </AnimateSplitText>
        <Spacer size={[28, 48]} />
        <HorizontalRule />
        <Spacer size={[12, 12]} />
        <Items regions={regions} />
      </Container>
      <Spacer size={[40, 80]} />
    </FeaturedWrapper>
  )
}

Featured.propTypes = {
  regions: PropTypes.array,
}

export default Featured
