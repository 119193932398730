import styled from 'styled-components'
import { cubicBezier } from '@styles/vars/cubicBezier.style'
import { animation } from '@styles/vars/animation.style'
import { clamp } from '@styles/utils/conversion.style'

export const AnimateImageMask = styled.div`
  ${clamp('border-radius', 12, 24)};
  overflow: hidden;
  position: relative;
  z-index: 1;
`

export const AnimateImageMain = styled.div.attrs(props => ({
  style: {
    transitionDelay: `${
      props.inView ? props.delay : animation.maskShowDuration
    }s`,
  },
}))`
  will-change: transform;
  opacity: ${props => (props.inView ? 1 : 0)};
  transform: ${props => (props.inView ? 'scale(1)' : `scale(1.1)`)};
  transition: transform 1.2s ${cubicBezier.out}, opacity 1.2s ${cubicBezier.out};

  &:hover {
    transform: ${props => (props.hover ? 'scale(1.03)' : 'scale(1)')};
  }

  > div {
    width: 100%;
  }
`
