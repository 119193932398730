import React from 'react'

const IconMapDot = ({ width = 17, height = 17, responsive = false }) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 17"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path d="M8.054.054a8 8 0 1 1 0 16 8 8 0 0 1 0-16Z" fill="#A9DFF1" />
      <path
        d="M11.085 5.881a.3.3 0 0 1 .26.45l-3.175 5.5a.3.3 0 0 1-.52 0l-3.175-5.5a.3.3 0 0 1 .26-.45h6.35Z"
        fill="#22223F"
      />
    </svg>
  )
}

export default IconMapDot
