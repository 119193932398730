import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  FiltersWrapper,
  FiltersMultiSelect,
  FiltersMultiSelectTrigger,
  FiltersMultiSelectOption,
  FiltersMultiSelectOptions,
  FiltersMultiSelectCheckBox,
  FiltersMultiSelectOptionsWrap,
  FiltersMultiSelectCount,
} from './index.style'
import { removeStringFromArray } from '@utils'
import IconArrowMultiSelect from '@svgs/IconArrowMultiSelect'
import Tick from '@svgs/Tick'
import GridItem from '@components/GridItem'
import Grid from '@components/Grid'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'

const SingleFilter = ({ label, setter, active, options, index, nameMap }) => {
  const [isOpen, setIsOpen] = useState(false)
  const $trigger = useRef()

  const removeFilters = () => {
    setter([])
  }

  const toggleFilter = tag => {
    if (active.includes(tag)) {
      const currentFilters = removeStringFromArray(active, tag)
      setter(currentFilters)
    } else {
      setter([...active, tag])
    }
  }

  useEffect(() => {
    const handleEsc = e => {
      if (e.keyCode === 27) {
        setIsOpen(false)
      }
    }

    const handlePageClick = e => {
      if (e.target === $trigger.current) return
      setIsOpen(false)
    }

    window.addEventListener('keydown', handleEsc)
    window.addEventListener('click', handlePageClick)

    return () => {
      window.removeEventListener('keydown', handleEsc)
      window.removeEventListener('click', handlePageClick)
    }
  }, [setIsOpen])

  return (
    <GridItem tabletL={3} style={{ zIndex: index }}>
      <AnimateFadeIn>
        <FiltersMultiSelect>
          <FiltersMultiSelectTrigger
            onClick={() => setIsOpen(!isOpen)}
            open={isOpen}
            ref={$trigger}
          >
            {label}{' '}
            <FiltersMultiSelectCount>
              {active.length > 0 ? `(${active.length})` : `All`}{' '}
              <IconArrowMultiSelect />
            </FiltersMultiSelectCount>
          </FiltersMultiSelectTrigger>
          <FiltersMultiSelectOptionsWrap>
            <FiltersMultiSelectOptions
              open={isOpen}
              onClick={e => e.stopPropagation()}
            >
              <li>
                <FiltersMultiSelectOption
                  onClick={removeFilters}
                  active={active.length === 0}
                  tabIndex={isOpen ? 0 : -1}
                >
                  All
                  <FiltersMultiSelectCheckBox>
                    <Tick />
                  </FiltersMultiSelectCheckBox>
                </FiltersMultiSelectOption>
              </li>
              {React.Children.toArray(
                options.map(tag => (
                  <li>
                    <FiltersMultiSelectOption
                      onClick={() => toggleFilter(tag.name)}
                      active={active.includes(tag.name)}
                      tabIndex={isOpen ? 0 : -1}
                    >
                      {nameMap
                        ? nameMap.find(n => n.key === tag.formattedName)?.name
                        : tag.formattedName}
                      <FiltersMultiSelectCheckBox>
                        <Tick />
                      </FiltersMultiSelectCheckBox>
                    </FiltersMultiSelectOption>
                  </li>
                ))
              )}
            </FiltersMultiSelectOptions>
          </FiltersMultiSelectOptionsWrap>
        </FiltersMultiSelect>
      </AnimateFadeIn>
    </GridItem>
  )
}

const Filters = ({
  activeMonths,
  setActiveMonths,
  activeYears,
  setActiveYears,
  activeLocation,
  setActiveLocation,
  activePort,
  setActivePort,
  months,
  years,
  locations,
  ports,
}) => {
  return (
    <FiltersWrapper>
      <Grid>
        <SingleFilter
          label="Year"
          setter={setActiveYears}
          active={activeYears}
          options={years}
          index={4}
          removeTagPrefix
        />
        <SingleFilter
          label="Month"
          setter={setActiveMonths}
          active={activeMonths}
          options={months}
          index={3}
          removeTagPrefix
          nameMap={[
            { name: 'January', key: '01' },
            { name: 'February', key: '02' },
            { name: 'March', key: '03' },
            { name: 'April', key: '04' },
            { name: 'May', key: '05' },
            { name: 'June', key: '06' },
            { name: 'July', key: '07' },
            { name: 'August', key: '08' },
            { name: 'September', key: '09' },
            { name: 'October', key: '10' },
            { name: 'November', key: '11' },
            { name: 'December', key: '12' },
          ]}
        />
        <SingleFilter
          label="Location"
          setter={setActiveLocation}
          active={activeLocation}
          options={locations}
          index={2}
        />
        <SingleFilter
          label="Port"
          setter={setActivePort}
          active={activePort}
          options={ports}
          index={1}
        />
      </Grid>
    </FiltersWrapper>
  )
}

Filters.propTypes = {
  activeMonths: PropTypes.array,
  setActiveMonths: PropTypes.func,
  activeYears: PropTypes.array,
  setActiveYears: PropTypes.func,
  activeLocation: PropTypes.array,
  setActiveLocation: PropTypes.func,
  activePort: PropTypes.array,
  setActivePort: PropTypes.func,
  months: PropTypes.array,
  years: PropTypes.array,
  locations: PropTypes.array,
  ports: PropTypes.array,
}

export default Filters
