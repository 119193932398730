import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'
import { mq } from '@styles/vars/media-queries.style'
import { Heading2 } from '@styles/vars/textStyles.style'

export const ItemWrapper = styled.div`
  ${clamp('padding-bottom', 36, 48)};
  ${clamp('padding-top', 36, 48)};
  position: relative;
  text-align: left;
  cursor: pointer;
`

export const ItemContent = styled.div`
  padding-top: 3.6rem;

  ${Heading2} {
    padding-bottom: 2.2rem;

    ${mq.tabletL} {
      padding-bottom: 1.2rem;
    }
  }
`

export const ItemModalCloseButton = styled.button`
  appearance: none;
  background-color: ${colors.grey};
  border: #4b4b63 solid 7px;
  border-radius: 50%;
  cursor: pointer;
  padding: 1.2rem;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(40%, -40%);

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
`

export const ItemModalContentNav = styled.div`
  display: flex;
  gap: 1.6rem;
  padding-bottom: 1.6rem;
`

export const ItemModalContent = styled.div`
  background-color: ${colors.grey};
  border-radius: 2.4rem;
  padding: 2.4rem;
  position: relative;
  text-align: center;
  max-width: 56rem;
  width: 95%;
`

// export const ItemAssets = styled.div`
//   padding: 5rem;
//   background-color: ${colors.grey};
//   border-radius: 1.6rem;
//   text-align: left;
// `

export const ItemModalContentList = styled.div`
  background-color: ${colors.light};
  border-radius: 1.6rem;
  padding: 3.2rem 1.6rem 0.2rem;

  a {
    display: block;
    text-decoration: none;
  }
`

// export const ItemAssetList = styled.div`
//   background-color: ${colors.light};
//   padding: 3.2rem 1.6rem 0.2rem;
//   border-radius: 1.6rem;

//   a {
//     text-decoration: none;
//   }
// `

export const ItemAssetButton = styled.div`
  ${mq.tabletP} {
    text-align: right;
  }
`

export const ItemAssets = styled.div``

export const ItemAssetList = styled.div`
  padding-top: 0;

  ${mq.tabletP} {
    padding-left: 2rem;
  }

  ${mq.tabletL} {
    ${clamp('padding-left', 40, 70)};
  }

  a {
    text-decoration: none;
  }
`
