import React from 'react'

const IconArrowMultiSelect = ({
  width = 12,
  height = 15,
  stroke = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 15"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        stroke="#2D68B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m11 8.5858-5 5m0 0-5-5m5 5V1.4142"
      />
    </svg>
  )
}

export default IconArrowMultiSelect
