import React from 'react'

const Tick = ({ width = 14, height = 13, responsive = false }) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 13"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        stroke="#fff"
        fill="transparent"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m1.0525 8.2673 5.1626 3.0575L12.3291 1"
      />
    </svg>
  )
}

export default Tick
