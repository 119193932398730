import React from 'react'
import PropTypes from 'prop-types'
import {
  MapWrapper,
  MapLocation,
  MapLocationButtonBottom,
  MapImageWrap,
  MapImage,
} from './index.style'
import { TextBody } from '@components/TextStyles'
import Spacer from '@components/Spacer'
import MapImageSrc from './images/map.jpg'
import RoundedImage from '@components/RoundedImage'
import IconArrowTop from '@svgs/IconArrowTop'
import Button from '@components/Button'
import IconMapDot from '@svgs/IconMapDot'
import AnimateImage from '@components/animation/AnimateImage'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import { useStore } from '@Store/index'
import { anchorScroll } from '@utils'

const Map = ({ tags, setActiveFilter }) => {
  const [store] = useStore()
  const { smoothScroll } = store

  const mapLocations = [
    {
      name: 'Europe',
      left: 51.5,
      top: 26.5,
    },
    {
      name: 'Alaska',
      left: 8,
      top: 15,
    },
    {
      name: 'Hawaii',
      left: 7.5,
      top: 42,
    },
    {
      name: 'Panama',
      left: 27.5,
      top: 49,
    },
    {
      name: 'Los Angeles',
      left: 17,
      top: 33,
    },
    {
      name: 'Australia',
      left: 92,
      top: 75,
    },
    {
      name: 'San Juan',
      left: 32,
      top: 43,
    },
    {
      name: 'Caribbean',
      left: 28,
      top: 39.5,
    },
    {
      name: 'Northeast',
      left: 29,
      top: 30,
    },
    {
      name: 'Asia',
      left: 79,
      top: 53,
    },
  ]

  return (
    <MapWrapper>
      <Spacer size={72} />
      <AnimateSplitText>
        <TextBody>
          Looking for a specific deployment region? Use the interactive map
          below to choose your destination.
        </TextBody>
      </AnimateSplitText>
      <Spacer size={48} />
      <MapImageWrap>
        {React.Children.toArray(
          tags.map(tag => {
            const locationDetails = mapLocations.find(
              location => location.name === tag.formattedName
            )

            if (!locationDetails)
              console.log(`No Location set up for ${tag.name}`)

            return locationDetails ? (
              <MapLocation
                left={locationDetails.left}
                top={locationDetails.top}
              >
                <IconMapDot />
                <Button
                  type="button"
                  onClick={() => {
                    setActiveFilter([tag.name])
                    anchorScroll(smoothScroll, '#deployment-list-anchor', 300)
                  }}
                  iconRight={<IconArrowTop />}
                  iconOrientation="vertical"
                >
                  {tag.formattedName} Deployment
                </Button>
                <MapLocationButtonBottom />
              </MapLocation>
            ) : (
              ``
            )
          })
        )}
        <RoundedImage>
          <AnimateImage delay={0} hover={false}>
            <MapImage
              src={MapImageSrc}
              alt="World Map with deployment locations"
            />
          </AnimateImage>
        </RoundedImage>
      </MapImageWrap>
    </MapWrapper>
  )
}

Map.propTypes = {
  tags: PropTypes.array,
  setActiveFilter: PropTypes.func,
}

export default Map
