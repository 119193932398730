import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import { AnimateImageMain, AnimateImageMask } from './index.style'
import { animation } from '@styles/vars/animation.style'
import { useStore } from '@Store/'

const AnimateImage = ({
  children,
  delay = 0,
  triggerOnce = true,
  animate = true,
  hover = true,
}) => {
  const [ref, inView] = useInView({
    triggerOnce,
    rootMargin: animation.rootMargin,
  })

  const [store] = useStore()
  const { showPageMask } = store

  return (
    <AnimateImageMask ref={ref}>
      <AnimateImageMain
        inView={inView && animate && !showPageMask}
        delay={delay}
        hover={hover}
      >
        {children}
      </AnimateImageMain>
    </AnimateImageMask>
  )
}

AnimateImage.propTypes = {
  children: PropTypes.node,
  delay: PropTypes.number,
  triggerOnce: PropTypes.bool,
  animate: PropTypes.bool,
  hover: PropTypes.bool,
}

export default AnimateImage
