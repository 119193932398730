import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  ItemWrapper,
  ItemContent,
  ItemModalContentNav,
  ItemModalContent,
  ItemModalContentList,
  ItemModalCloseButton,
} from './index.style'
import { Heading2, TextBody } from '@components/TextStyles'
import GridItem from '@components/GridItem'
import RoundedImage from '@components/RoundedImage'
import { GatsbyImage } from 'gatsby-plugin-image'
import Spacer from '@components/Spacer'
import Button from '@components/Button'
import Link from '@components/Link'
import { ListItem, UnorderedList } from '@components/RichText/index.style'
import AnimateImage from '@components/animation/AnimateImage'
import { useInView } from 'react-intersection-observer'
import { animation } from '@styles/vars/animation.style'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'
import Modal from '@components/Modal'
import IconArrowRight from '@svgs/IconArrowRight'
import Close from '@svgs/Close'
import { getMatchingAssets } from '@utils'

const ItemDeployments = ({
  close,
  title,
  deployments,
  initialDeployment,
  resetInitialDeployment,
  activeFilters,
}) => {
  const [active, setActive] = useState(0)
  const [filteredDeployments, setFilteredDeployments] = useState(deployments)
  const [activeAssets, setActiveAssets] = useState(deployments?.[0]?.assets)

  useEffect(() => {
    if (!activeFilters) {
      setFilteredDeployments(deployments)
      return
    }

    const matchingDeployments = []

    deployments.forEach(deployment => {
      if (getMatchingAssets(deployment, activeFilters).length > 0) {
        matchingDeployments.push(deployment)
      }
    })

    setFilteredDeployments(matchingDeployments)
  }, [deployments, activeFilters])

  useEffect(() => {
    if (!activeFilters) {
      setActiveAssets(deployments?.[active]?.assets)
      return
    }

    setActiveAssets(
      getMatchingAssets(filteredDeployments[active], activeFilters)
    )
  }, [filteredDeployments, active, activeFilters])

  useEffect(() => {
    if (!initialDeployment) return

    const deploymentIndex = deployments.findIndex(
      deployment => deployment.anchor === initialDeployment
    )

    if (deploymentIndex > -1) {
      setActive(deploymentIndex)
      resetInitialDeployment()
    }
  }, [initialDeployment])

  return (
    <ItemModalContent>
      <ItemModalCloseButton onClick={close}>
        <Close />
      </ItemModalCloseButton>
      <TextBody>{title}</TextBody>
      <Spacer size={16} />
      <ItemModalContentNav>
        {React.Children.toArray(
          filteredDeployments.map((deployment, index) => {
            return (
              <Button
                fill={1}
                onClick={() => setActive(index)}
                variant={active === index ? `secondary` : `primary`}
              >
                {deployment.tabTitle}
              </Button>
            )
          })
        )}
      </ItemModalContentNav>
      <ItemModalContentList>
        <UnorderedList>
          {React.Children.toArray(
            activeAssets.map(asset => {
              return (
                <AnimateFadeIn>
                  <ListItem>
                    <Link page={asset.page} url={asset.url} textLink={true}>
                      <AnimateSplitText>{asset.text}</AnimateSplitText>
                    </Link>
                  </ListItem>
                </AnimateFadeIn>
              )
            })
          )}
        </UnorderedList>
      </ItemModalContentList>
    </ItemModalContent>
  )
}

const Item = ({
  classes,
  region,
  initialDeployment,
  resetInitialDeployment,
  activeFilters,
}) => {
  const [open, setOpen] = useState(false)

  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: animation.rootMargin,
  })

  useEffect(() => {
    if (!initialDeployment) return

    const deploymentIndex = region.linkedDeployments.findIndex(
      deployment => deployment.anchor === initialDeployment
    )

    if (deploymentIndex > -1) {
      setOpen(true)
    }
  }, [initialDeployment])

  return (
    <GridItem tabletL={4}>
      <Modal
        isOpen={open}
        onDismiss={() => {}}
        ariaLabel="Title"
        closeEvent={() => {
          setOpen(false)
        }}
      >
        {region.linkedDeployments && region.linkedDeployments.length ? (
          <ItemDeployments
            close={() => setOpen(false)}
            title={region.title}
            deployments={region.linkedDeployments}
            initialDeployment={initialDeployment}
            resetInitialDeployment={resetInitialDeployment}
            activeFilters={activeFilters}
          />
        ) : (
          ``
        )}
      </Modal>
      <ItemWrapper
        ref={ref}
        inView={inView}
        id={`region-${region.contentful_id}`}
        className={classes}
        onClick={() => setOpen(true)}
      >
        {region.image && (
          <RoundedImage>
            <AnimateImage delay={0}>
              <GatsbyImage
                image={region.image.gatsbyImageData}
                alt={region.image.description}
              />
            </AnimateImage>
          </RoundedImage>
        )}
        <ItemContent>
          <Heading2>
            <AnimateSlideIn>{region.title}</AnimateSlideIn>
          </Heading2>
          <Spacer size={10} />
          <AnimateFadeIn>
            <Button
              iconRight={<IconArrowRight />}
              iconOrientation="vertical"
              onClick={() => setOpen(true)}
            >
              View Assets
            </Button>
          </AnimateFadeIn>
        </ItemContent>
      </ItemWrapper>
    </GridItem>
  )
}

Item.propTypes = {
  classes: PropTypes.string,
  region: PropTypes.object,
}

export default Item
