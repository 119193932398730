import React, { useEffect, useCallback, useState } from 'react'
import { graphql } from 'gatsby'
import Seo from '@components/Seo'
import Footer from '@components/Footer'
import Spacer from '@components/Spacer'
import List from '@components/Deployments/List'
import { getTagsFromItems } from '@utils'
import Featured from '@components/Deployments/Featured'

const DeploymentsPage = ({ data, location }) => {
  const [initialDeployment, setInitialDeployment] = useState(null)

  const { seo, slug, deploymentBlocks } = data.contentfulDeployments

  const regionArray = data.allContentfulDeploymentRegion.nodes

  // Remove regions in deploymentBlocks from regionArray
  deploymentBlocks.forEach(deploymentBlock => {
    const index = regionArray.findIndex(
      region => region.contentful_id === deploymentBlock.contentful_id
    )

    if (index > -1) {
      regionArray.splice(index, 1)
    }
  })

  const individualDeployments = []

  regionArray.forEach(region => {
    if (!region.linkedDeployments) {
      return
    }

    region.linkedDeployments.forEach(deployment => {
      if (
        !individualDeployments.some(
          d => d.contentful_id === deployment.contentful_id
        )
      )
        individualDeployments.push(deployment)
    })
  })

  let yearTags = getTagsFromItems(individualDeployments, 'Deployment Year:'),
    monthTags = getTagsFromItems(individualDeployments, 'Deployment Month:'),
    locationTags = getTagsFromItems(
      individualDeployments,
      'Deployment Location:'
    ),
    portTags = getTagsFromItems(individualDeployments, 'Deployment Port:')

  const sortTagByName = (a, b) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  }

  // Sort tag arrays
  yearTags.sort(sortTagByName)
  monthTags.sort(sortTagByName)
  locationTags.sort(sortTagByName)
  portTags.sort(sortTagByName)

  /**
   * Scroll to deployment
   */
  const checkPresetDeploymentFromUrl = useCallback(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const id = urlParams.get('id')

    setTimeout(() => {
      setInitialDeployment(id)
    }, 1000)
  }, [])

  useEffect(() => {
    checkPresetDeploymentFromUrl()
  }, [checkPresetDeploymentFromUrl])

  return (
    <>
      <Seo data={seo} slug={slug} />
      <Spacer size={[180, 300]} />
      {deploymentBlocks && deploymentBlocks.length > 0 && (
        <Featured regions={deploymentBlocks} />
      )}
      <List
        yearTags={yearTags}
        monthTags={monthTags}
        locationTags={locationTags}
        portTags={portTags}
        regions={data.allContentfulDeploymentRegion.nodes}
        location={location}
        initialDeployment={initialDeployment}
        resetInitialDeployment={() => setInitialDeployment(null)}
      />
      <Spacer size={100} />
      <Footer location={location} />
    </>
  )
}

export default DeploymentsPage

export const pageQuery = graphql`
  query DeploymentsPage {
    contentfulDeployments {
      slug
      seo {
        ...SEO
      }
      deploymentBlocks {
        contentful_id
        title
        image {
          gatsbyImageData(aspectRatio: 1.6)
          description
        }
        linkedDeployments {
          contentful_id
          metadata {
            tags {
              name
            }
          }
          title
          tabTitle
          anchor
          assets {
            ...Link
          }
        }
      }
    }
    allContentfulDeploymentRegion(sort: { fields: [title], order: [ASC] }) {
      nodes {
        contentful_id
        title
        image {
          gatsbyImageData(aspectRatio: 1.6)
          description
        }
        linkedDeployments {
          contentful_id
          metadata {
            tags {
              name
            }
          }
          title
          tabTitle
          anchor
          assets {
            metadata {
              tags {
                name
              }
            }
            ...Link
          }
        }
      }
    }
  }
`
