import React from 'react'

const Close = ({
  width = 18,
  height = 18,
  fill = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="M16.0709 17.4853C16.4614 17.8758 17.0946 17.8758 17.4851 17.4853C17.8756 17.0948 17.8756 16.4616 17.4851 16.0711L10.4141 9L17.4851 1.92893C17.8756 1.53844 17.8756 0.905216 17.4851 0.514719C17.0946 0.124222 16.4614 0.124222 16.0709 0.514719L8.99986 7.58579L1.92879 0.514719C1.53829 0.124222 0.905075 0.124222 0.514578 0.514719C0.124081 0.905216 0.124081 1.53844 0.514578 1.92893L7.58565 9L0.514578 16.0711C0.124081 16.4616 0.124081 17.0948 0.514578 17.4853C0.905075 17.8758 1.53829 17.8758 1.92879 17.4853L8.99986 10.4142L16.0709 17.4853Z"
        fill={fill}
      />
    </svg>
  )
}

export default Close
