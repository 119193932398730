import styled from 'styled-components'
import { colors } from '@styles/vars/colors.style'

export const HorizontalRuleWrapper = styled.div`
  background-color: ${colors.darkblue};
  content: '';
  height: 0.1rem;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`
