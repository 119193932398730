import styled from 'styled-components'
import { mq } from '@styles/vars/media-queries.style'
import { ButtonMain } from '@components/Button/index.style'
import { colors } from '@styles/vars/colors.style'
import { cubicBezier } from '@styles/vars/cubicBezier.style'

export const MapWrapper = styled.div`
  display: none;
  text-align: left;

  ${mq.desk} {
    display: block;
    padding-bottom: 10rem;
  }
`

export const MapLocationButtonBottom = styled.div`
  bottom: 100%;
  height: 1rem;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  transform: translateY(1rem);

  &:before {
    background-color: ${colors.light};
    border-radius: 0.2rem;
    bottom: 50%;
    content: '';
    height: 1.1rem;
    left: 50%;
    position: absolute;
    transform: translateX(-50%) rotateZ(45deg);
    width: 1.1rem;
  }
`

export const MapLocation = styled.div.attrs(props => ({
  style: {
    left: `${props.left}%`,
    top: `${props.top}%`,
  },
}))`
  align-items: center;
  justify-content: center;
  display: flex;
  opacity: ${props => (props.faded ? 0.5 : 1)};
  pointer-events: ${props => (props.faded ? `none` : `auto`)};
  position: absolute;
  height: 4rem;
  transform: translateX(-50%) translateY(-50%) translateZ(0);
  transition: opacity 0.6s ${cubicBezier.inOut};
  width: 4rem;
  z-index: ${props => (props.active ? 4 : 2)};

  ${MapLocationButtonBottom},
  ${ButtonMain} {
    transition: opacity 0.3s ${cubicBezier.inOut};
  }

  ${ButtonMain} {
    bottom: 100%;
    left: 50%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transform: translateX(-50%);
  }

  &:hover {
    z-index: 4;

    ${MapLocationButtonBottom},
    ${ButtonMain} {
      opacity: 1;
      transition: opacity 0.6s ${cubicBezier.inOut};
    }
    ${ButtonMain} {
      pointer-events: auto;
    }
  }

  ${props =>
    props.active &&
    `
    ${MapLocationButtonBottom},
    ${ButtonMain} {
      opacity: 1;
      transition: opacity 0.6s ${cubicBezier.inOut};
    }
    ${ButtonMain} {
      pointer-events: auto;
    }
  `}
`

export const MapImageWrap = styled.div`
  position: relative;
`

export const MapImage = styled.img``
