import React from 'react'
import PropTypes from 'prop-types'
import { HorizontalRuleWrapper } from './index.style'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'

const HorizontalRule = ({ delay }) => {
  return (
    <AnimateFadeIn delay={delay}>
      <HorizontalRuleWrapper />
    </AnimateFadeIn>
  )
}

HorizontalRule.propTypes = {
  delay: PropTypes.number,
}

HorizontalRule.defaultProps = {
  delay: 0,
}

export default HorizontalRule
